// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import Nette from './nette/NetteForms'
window.Nette = Nette
window.addEventListener('naja:afterupdate', () => Nette.reinit())
window.addEventListener('modal:load', () => Nette.reinit())
Nette.initOnLoad()

import NajaService from './services/Naja'
NajaService.initialize()
NajaService.formsHandler.netteForms = Nette

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'
ScrollService.useNative()

import { loadComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from "lazysizes"
lazySizes.cfg.lazyClass = "is-lazy"
lazySizes.cfg.loadingClass = "is-lazy-loading"
lazySizes.cfg.loadedClass = "is-lazy-loaded"
document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg")
    if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")"
    }

})

import { initModalBlob } from './utils/initModalBlob'
import { Naja } from 'naja'
initModalBlob()