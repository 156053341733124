import { Naja } from 'naja'
import { SnippetHandler } from './SnippetHandler'
import { HistoryHandler } from './HistoryHandler'
import { NetteLoadingExtension } from './extensions/NetteLoadingExtension'
import { NetteEventsExtension } from './extensions/NetteEventsExtension'
import { NetteSnippetsExtension } from './extensions/NetteSnippetsExtension'

const naja = new Naja(null, null, SnippetHandler, null, HistoryHandler, null,)

naja.registerExtension(new NetteLoadingExtension)
naja.registerExtension(new NetteEventsExtension)
naja.registerExtension(new NetteSnippetsExtension)

window.addEventListener('modal:load', (event) => {
    const {element} = event.detail
    if (element) {
        naja.uiHandler.bindUI(element)
    }
})

export default naja