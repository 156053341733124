import ScrollService from './NativeScroll'
// import ScrollService from './VirtualScroll'
import {queries} from '../../core/config'

ScrollService.getScrollOffset = () => {
    if (window.matchMedia(queries.smallWideMax).matches) {
        const opener = document.querySelector('.PopupNavOpener--fixed ')
        if (!opener) {
            return 0
        }
        const openerRect = opener.getBoundingClientRect()
        return openerRect.top + openerRect.height + 10
    } else {
        return 0
    }
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService