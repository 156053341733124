import EventBus from "../core/EventBus"
import Scroll from "../services/Scroll"
import anime from "animejs"

const modals = {}
let lastTl = null

function handleModalBeforeOpen(info) {
    if (!info.triggerer) {
        return
    }

    const { modal, triggerer } = info

    modals[info.id] = {
        modal,
        triggerer,
        scrollBeforeOpen: Scroll.getScroll(),
    }
    modal.element.classList.add("is-animo")
    triggerer.element.classList.add("is-animo")
}

function handleModalOpen(info) {
    const _info = modals[info.id]
    if (!_info) {
        return
    }

    const { modal } = _info
    animate(_info)
    modal.element.classList.remove("is-active")
}

function handleModalClose(info) {
    const _info = modals[info.id]
    if (!_info) {
        return
    }

    const { modal } = _info
    animate(_info, "reverse").then(() => {
        modal.element.classList.remove("is-animo")
        modal.element.classList.remove("is-visible")
    })
    delete modals[info.id]
}

function getElementDocumentRect(element, scroll) {
    const { x: scrollX, y: scrollY } = scroll
    const rect = element.getBoundingClientRect()

    return {
        x: scrollX + rect.x,
        y: scrollY + rect.y,
        width: rect.width,
        height: rect.height,
    }
}

function animate({ modal, triggerer, scrollBeforeOpen }, direction = "normal") {
    const from = getElementDocumentRect(triggerer.element, scrollBeforeOpen)
    const to = getElementDocumentRect(modal.ref.content, scrollBeforeOpen)
    const mid = {
        x: to.x,
        y: from.y,
        width: to.width,
        height: from.height,
    }

    const blob = {
        element: document.createElement("div"),
    }
    blob.element.className = "ModalBlob"
    document.body.appendChild(blob.element)

    const tl = anime.timeline({
        easing: "easeOutSine",
        autoplay: false,
        direction,
        duration: 1,
    })

    tl.add({
        targets: [modal.ref.content, modal.ref.text, modal.ref.background],
        opacity: [1, 0],
        direction,
        duration: 1,
    })
        .add({
            targets: modal.element,
            opacity: [0, 1],
            direction,
            duration: 1,
        })
        .add({
            targets: blob.element,
            top: [0, from.y],
            left: [0, from.x],
            width: [0, from.width],
            height: [0, from.height],
        })
        .add({
            targets: triggerer.element,
            clipPath: ["inset(-50% -50% -50% -50%)", "inset(50% -50% 50% -50%)"],
            duration: 400,
            easing: "easeOutQuad",
        })
        .add(
            {
                targets: blob.element,
                opacity: [0, 1],
                scaleY: [0, 0],
                duration: 200,
            },
            "-=200"
        )
        .add(
            {
                targets: modal.ref.background,
                opacity: [0, 1],
                direction,
                duration: 200,
            },
            "-=200"
        )
        .add(
            {
                targets: blob.element,
                left: [from.x, mid.x],
                top: [from.y, mid.y],
                width: [from.width, mid.width],
                height: [from.height, mid.height],
                duration: 200,
            },
            "-=200"
        )
        .add({
            targets: blob.element,
            left: [mid.x, to.x],
            top: [mid.y, to.y],
            width: [mid.width, to.width],
            height: [mid.height, to.height],
            duration: 300,
        })
        .add(
            {
                targets: blob.element,
                scaleY: [0.0, 1],
                duration: 400,
                easing: "easeOutQuad",
            },
            "-=200"
        )
        .add({
            targets: modal.ref.content,
            opacity: [0, 1],
            duration: 100,
        })
        .add({
            targets: blob.element,
            opacity: [1, 0],
            duration: 1,
        })
        .add({
            targets: modal.ref.text,
            opacity: [0, 1],
            duration: 200,
        })

    if (direction === "reverse") {
        modal.element.style.opacity = 1
        modal.ref.text.style.opacity = 1
        modal.ref.content.style.opacity = 1
        triggerer.element.style.clipPath = "inset(50% -50% 50% -50%)"
        modal.ref.background.style.opacity = 1
        blob.element.style.opacity = 0
        blob.element.style.width = `${to.width}px`
        blob.element.style.height = `${to.height}px`
        blob.element.style.top = `${to.y}px`
        blob.element.style.left = `${to.x}px`
        blob.element.style.transform = `scaleY(1)`
        tl.finished.then(() => {
            triggerer.element.style.clipPath = ``
        })
    }

    tl.finished.then(() => {
        blob.element.parentNode.removeChild(blob.element)
    })

    tl.play()

    return tl.finished
}

export function initModalBlob() {
    EventBus.on("modal:beforeopen", handleModalBeforeOpen)
    EventBus.on("modal:open", handleModalOpen)
    EventBus.on("modal:close", handleModalClose)
}
